(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tb-ui/corporate-info/assets/javascripts/corporate-info.js') >= 0) return;  svs.modules.push('/components/tb-ui/corporate-info/assets/javascripts/corporate-info.js');

var svs = svs || {};
(function(svs) {
  'use strict';

  var $body = $('body'),
    header = $('.js-fixed-top-content'),
    corporateInformationPreventAnimationClass = 'corporate-info-animation-disabled';

  if (header.hasClass('corporate-info-fixed')) {
    if (svs.core.detect.breakpoint.greaterThan('md')) {
      $body.addClass(corporateInformationPreventAnimationClass);
      svs.core.detect.breakpoint.on('change', function() {
        if (svs.core.detect.breakpoint.lessThan('lg')) {
          $body.removeClass(corporateInformationPreventAnimationClass);
        }
      });
    }
  } else {
    if (svs.core.detect.breakpoint.greaterThan('md')) {
      $body.addClass(corporateInformationPreventAnimationClass);
    } else {
      svs.core.detect.breakpoint.on('change', function() {
        if (svs.core.detect.breakpoint.greaterThan('md')) {
          $body.addClass(corporateInformationPreventAnimationClass);
        }
      });
    }
  }
})(svs);


 })(window);