(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tb-ui/corporate-info/views/corporate-info.js') >= 0) return;  svs.modules.push('/components/tb-ui/corporate-info/views/corporate-info.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.tb_ui=_svs.tb_ui||{};
_svs.tb_ui.corporate_info=_svs.tb_ui.corporate_info||{};
_svs.tb_ui.corporate_info.templates=_svs.tb_ui.corporate_info.templates||{};
svs.tb_ui.corporate_info.templates.corporate_info = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isSelfService") || (depth0 != null ? lookupProperty(depth0,"isSelfService") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isSelfService","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":0},"end":{"line":14,"column":18}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isSelfService")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(container,depth0,helpers,partials,data) {
    return "<div class=\"corporate-info-legal-info\">Spel från AB Svenska Spel</div>\n<div class=\"clearfix visible-lg-up\"></div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"corporate-info-legal-info\">Spel från AB Svenska Spel</div>\n<div class=\"clearfix visible-lg-up\"></div>\n<div class=\"corporate-info-ao-brand corporate-info-ao-brand-tur\">\n  <div class=\"corporate-info-ao-brand-content\">\n    <img class=\"corporate-info-ao-brand-divider\" src=\""
    + container.escapeExpression((lookupProperty(helpers,"getAssetUrl")||(depth0 && lookupProperty(depth0,"getAssetUrl"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/images/components/ao-logotype-tur/ao-tur.svg",{"name":"getAssetUrl","hash":{},"data":data,"loc":{"start":{"line":10,"column":54},"end":{"line":10,"column":117}}}))
    + "\"\n      alt=\"Svenska Spel Tur\" />\n  </div>\n</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":15,"column":11}}})) != null ? stack1 : "");
},"useData":true});
Handlebars.partials['components-tb_ui-corporate_info-corporate_info'] = svs.tb_ui.corporate_info.templates.corporate_info;
})(svs, Handlebars);


 })(window);